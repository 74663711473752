<template>
    <header class=" ">
        <div class="" >
            <div class="container">
                <nav class="navbar">
                    <a href="/" class="">
                        <img src="@/assets/edutep_logo_texto.png" width="115px" height="50px">
                    </a>
                </nav>
            </div>
        </div>
    </header>
</template>

<script>
export default {
  name: "HeaderItem",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
