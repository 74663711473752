<template>
    <div v-if="result" class="posts">
        Posts info

        <ul id="example-1">
            <li v-for="post in result" :key="post.id" >
                {{ post.title }}
            </li>
        </ul>

    </div>
</template>

<script>
import axios from "axios";
export default {
    data: () => ({
        result: null,
    }),
    created() {
        axios.get("https://jsonplaceholder.typicode.com/posts").then((result) => {
            this.result = result.data;
        });
    },
};
</script>