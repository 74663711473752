<template>
  
  <div class="container px-4 py-5">
    <h2 class="pb-2 border-bottom">Ubicación</h2>
    <h4>C. Valle Azul #3125, Valle Alto, 80050 Sin.</h4>




    <l-map style="height: 300px" :zoom="zoom" :center="center">
        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
        <l-marker :lat-lng="markerLatLng"></l-marker>
    </l-map>

  </div>


</template>

<script>
import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer, LMarker } from "@vue-leaflet/vue-leaflet";

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker
  },
  data () {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:'&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 15,
      center: [24.80833639537505, -107.4616217185698],
      markerLatLng: [24.80833639537505, -107.4616217185698]
    };
  },
};
</script>

<style></style>