<template>

  <div>
    <div class="container">
    <footer class="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
      <p class="col-md-4 mb-0 text-body-secondary">© 2024 Todos los derechos reservados.</p>

      <!-- <ul class="nav col-md-4 justify-content-end">
        <li class="nav-item"><router-link :to="{ name: 'Home' }" class="nav-link px-2 text-body-secondary">Home</router-link></li>
        <li class="nav-item"><router-link :to="{ name: 'About' }" class="nav-link px-2 text-body-secondary">About</router-link> </li>
        <li class="nav-item"><router-link :to="{ name: 'Calendar' }" class="nav-link px-2 text-body-secondary">Calendar</router-link> </li>
        <li class="nav-item"><router-link :to="{ name: 'Posts' }" class="nav-link px-2 text-body-secondary">Posts</router-link> </li>
      </ul> -->
    </footer>
    </div>
    <a class="whatsappHref" href="https://wa.me/526673956886?text=Hola" target="_blank"></a>
  </div>


</template>

<script>
export default {
  name: 'FooterItem',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.whatsappHref{
  display: block;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background: url("https://gus-static.s3.amazonaws.com/whats.png") center center / 50px 50px no-repeat transparent;
  cursor: pointer;
  z-index: 999999;
  text-decoration: none;
  outline: none;
}
</style>
