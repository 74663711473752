<template>
  <div class="home">
    <InicioItem />
    <ServiciosItem />
    <MapItem />
  </div>
</template>


<script>
// @ is an alias to /src
import InicioItem from '@/components/InicioItem.vue';
import ServiciosItem from '@/components/ServiciosItem.vue';
import MapItem from '@/components/MapItem.vue';

export default {
  name: 'HomeView',
  components: {
    InicioItem,
    ServiciosItem,
    MapItem
  }
}
</script>
