<template>
<div>
  <div class="services">
      <div class="container px-4 py-5">
        <h1 class="display-5 fw-bold text-center border-bottom">Servicios</h1>



        <div class="row align-items-md-center g-5 py-5">

            <div class="col-md-12 col-lg-6">
            
            <div  class="row row-cols-1 g-5">

                <div v-for="item in serviceList" :key="item.id" class="col d-flex flex-column gap-2">
                
                <h4 class="fw-semibold mb-0 text-body-emphasis">
                    {{ item.nombre }}
                </h4>
                
                <p class="text-body-secondary lead">
                    {{ item.descripcion }}
                </p>

                </div>

            </div>

            </div>

            <div class="col">
                <img class="d-block mx-auto img-fluid" src="@/assets/foco.png" alt=""> 
            </div>

        </div>

    </div>
  </div>
  <div class="services2 px-4 py-5">
      <div class="container">
          <div class="row gap-4">
            <h1 class="display-5 ">¿Qué es el autismo?</h1>
            <p class="lead">Es una afección relacionada con el desarrollo del cerebro que afecta la manera en la que una persona percibe y socializa con otras personas, lo que causa problemas en la interación social y la comunicación.</p>
          </div>
          <div class="row ">
            <div class="col-sm-12 col-md-6">
            <h3 class="pt-5 ">¿Cómo lo detecto?</h3>
                <p class="text-body-secondary ">No mantiene contacto visual</p>
                <p class="text-body-secondary ">Usar pocos o ningún gesto</p>
                <p class="text-body-secondary ">No notar a otros niños ni jugar con ellos</p>
                <p class="text-body-secondary ">Irritarse con cambios minimos</p>
                <p class="text-body-secondary ">Jugar con juguetes de la misma manera todo el tiempo</p>
                <p class="text-body-secondary ">Tener intereses obsesivos</p>
                <p class="text-body-secondary ">Aletear las manos, mecer el cuerpo o girar en circulos</p>
            </div>
            <div class="col-sm-12 col-md-6">
                <img class="d-block mx-auto img-fluid img-astro" src="@/assets/leyendo.png" alt=""> 
            </div>

          </div>
          <div class="row">
              <div class="col">
                  <p class="lead text-body-emphasis ">
                      En EDUTEP nuestro objetivo es promover el desarrollo integral de los alumnos con autismo y potenciar al máximo sus capacidades de desarrollo personal y de participación en la comunidad, así como favorecer su integración social y el funcionamiento para facilitar el aprendizaje académico.
                  </p>
              </div>
          </div>
         
      </div>
  </div>
  <div class="services3 px-4 py-5">
      <div class="container">
          <div class="row align-items-md-center text-center">
              <h1 class="display-5">Reforzamiento académico - club de tareas</h1>
            
            <div class="col-sm-12 col-md-6">
                <img class="d-block mx-auto img-fluid img-astro" src="@/assets/lapiz.png" alt=""> 
            </div>
            
            <div class="col-sm-12 col-md-6 align-items-md-center">
                <p class="lead text-body-emphasis col-lg-10 fs-2 ">
                    Mejora el aprendizaje en los estudiantes y la calidad educativa reduciendo los niveles de deserción y aumentando su nivel de retención.
                </p>
            </div>

         
      </div>
      </div>
  </div>
</div>
</template>

<script setup>
import { ref } from 'vue'

const serviceList = ref([
    {
        id: 0,
        nombre: 'Terapia de lenguaje',
        imagen: 'foco.png',
        descripcion: 'Conjunto de técnicas y estrategias de interacción utilizadas para lograr la comunicación del niño con su entorno. Así mismo, es un elemento vital para el desarrollo cognitivo del menor, ya que forma su capacidad de pensar en cómo comunicarse.' 
    },
    { 
        id: 1,
        nombre: 'Terapia sensorial',
        imagen: 'avion.png',
        descripcion: 'Es el proceso neurológico que integra y organiza todas las sensaciones que experimentamos de nuestro propio cuerpo y del exterior, nos permite generar una respuesta adaptada a las demandas del entorno. Uno de los beneficios que obtendremos es una mejora en la coordinación motora.' 
    },
    { 
        id: 2,
        nombre: 'Terapia cognitivo - conductual',
        imagen: 'cohete.png',
        descripcion: 'Enseña a los alumnos a controlar sus pensamientos para que puedan tener un mejor manejo de sus sentimientos no deseados y prevenir comportamientos problemáticos, lo que llevara a un mejor desempeño en la escuela, casa y en sus relaciones sociales.' 
    },
    { 
        id: 4,
        nombre: 'Terapia psicológica',
        imagen: 'corazon.png',
        descripcion: 'Buscamos mejorar la autoestima, desarrollar habilidades sociales, liberar tención y ansiedad, obteniendo el bienestar emocional.' 
    },
])

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.services {
  background-color: #f0fbfe;
  position: relative;
}
.services2 {
  background-color: #e4f2ff;
  position: relative;
}
.services3 {
  background-color: #d1e6fe;
  position: relative;
}
.img-astro{
    max-height: 25em;
}
</style>
