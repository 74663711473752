<template>
    <div class="titulo-inicio">
        <div class="container text-center">
            <div class="row px-4 py-5 my-5 align-items-center ">

                <div class="col-md-6">
                    <img class="d-block mx-auto img-fluid" src="../assets/tierra.png" alt="" >
                </div>
                <div class="col-md-6 text-white">
                    <h1 class="fw-bold ">EDUCACIÓN ESPECIAL TERAPÉUTICA Y PSICOLÓGICA</h1>
                    <p class="lead m-4 fs-4">Somos un centro terapéutico que busca la independencia y la felicidad de cada niño, descubriendo sus capacidades y adaptándonos a cada aspecto de su vida, nos interesa que sean unos niños libres y amados. </p>
                </div>
                
            </div> 
        </div>
    </div>

</template>

<script>
export default {
  name: 'InicioItem'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.limones{
    background-color: #f0fbfe;
}
.titulo-inicio{
    min-height: 600px;
    display: flex;
    align-items: center;
    background-image: linear-gradient(rgba(0, 40, 85, 0.7), rgba(0, 40, 85, 0.7)), url("../assets/v760-toon-50.jpg");
    background-size: cover;
    text-align: center;
    padding: 30px 0;
}
</style>
